import React from 'react';

import AboutAccent from '../img/about_us_accent.svg'
import AboutUs from '../img/about_us.svg'
import Tracks from '../img/tracks.svg'

export default function() {
    return(

        <div id='About'>
            <div className='conatiner'>
                <div className='row pad-bottom-200 pad-bottom-100-mobile'>
                    <div className='col-lg-6'>
                        <div className='row'>
                            <div className='col-lg-3 desk'>
                                <img src={AboutAccent} alt='' />
                            </div>
                            <div className='col-lg-9'>
                                <h2 className='text-purple italic'>Ottawa's largest hackathon has turned 3!</h2>
                                <p className='justify'>Hosted at the University of Ottawa, uOttaHack is Ottawa's largest hackathon and this year will be its most exciting iteration yet! To commemorate this special milestone, uOttaHack 3 is expanding the hacker experience with
                                    new initiatives to create an unforgettable weekend for its hackers. </p>
                                <p className='justify'>Now a 36 hour long hackathon, uOttaHack 3 will welcome over 400 students on <b>February 7th-9th</b> from a diverse set of backgrounds to collaborate on projects, learn at workshops, and build out their innovative ideas.</p>

                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <img className='img-responsive' src={AboutUs} alt='' />
                    </div>
                </div>
                <div className='row column'>
                    <div className='col-lg-6 offset-lg-3'>
                        <h2 className='text-purple italic'>Introducing Hacking TRACKS</h2>
                        <p className='justify'>Also new for uOttaHack 3 is the addition of hacking TRACKS, specific categories for hackers to build their projects around and address problems in the real world. Teams will still have a large amount of creative freedom with their
                            projects, while also collectively working on wider issues.</p>
                    </div>
                    <img className='top-bump desk' src={Tracks} alt='' />
                </div>
            </div>
        </div>
    )
}
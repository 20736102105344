// Page Dependencies 
import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import GodHeader from '../../components/GodHeader'

import Application from './Application.jsx'
import Applications from './Applications.jsx'

import Volunteer from './Volunteer.jsx'
import Volunteers from './Volunteers.jsx'

import Mentor from './Mentor.jsx'
import Mentors from './Mentors.jsx'

import Checkin from './CheckIn.jsx'
import Checkins from './CheckIns.jsx'

import Users from './Users.jsx'
import Dashboard from './Dashboard.jsx'
import SingleUser from './SingleUser.jsx'

function Routes({ match, history }) {

    return (
        <Router>
            <GodHeader history={history} />
            <div className="ui container white-background">
                <Route path={`${match.url}/`} exact component={Dashboard} />
                <Route path={`${match.url}/Dashboard`} component={Dashboard} />
                <Route path={`${match.url}/Users`} component={Users} />
                <Route path={`${match.url}/User/:user`} component={SingleUser} />
                <Route path={`${match.url}/Applications`} component={Applications} />
                <Route path={`${match.url}/Application/:application`} component={Application} />
                <Route path={`${match.url}/Volunteers`} component={Volunteers} />
                <Route path={`${match.url}/Volunteer/:application`} component={Volunteer} />
                <Route path={`${match.url}/Mentors`} component={Mentors} />
                <Route path={`${match.url}/Mentor/:application`} component={Mentor} />
                <Route path={`${match.url}/Checkins/`} component={Checkins} />
                <Route path={`${match.url}/Checkin/:id`} component={Checkin} />
            </div>
        </Router>
    );
}

export default Routes;
// Page Dependencies 
import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import God from './God';

// Controllers
import Home from './views/Home.jsx'
import GodPage from './views/admin/GodPage.jsx'

import firebase from 'firebase/app'

import "./scss/main.scss";
import "./fonts/inter.css";

const firebaseConfig = {
    apiKey: "AIzaSyDkt86eO1SZFbxE6J11Ffa3UaKmKBI3Agg",
    authDomain: "uottahack-efc5d.firebaseapp.com",
    databaseURL: "https://uottahack-efc5d.firebaseio.com",
    projectId: "uottahack-efc5d",
    storageBucket: "uottahack-efc5d.appspot.com",
    messagingSenderId: "1016220468732",
    appId: "1:1016220468732:web:99bc6b7443b54ea9c69e79"
};

firebase.initializeApp(firebaseConfig)

function App() {
    return (
        <Router>
            <Route exact path="/" component={Home} />
            <Route path="/Admin" component={God(GodPage)} />
        </Router>
    );
}

export default App;
import React from 'react';
import cookie from 'react-cookies';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            applicants: 0,
            travellingFrom: [],
            shirts: [],
            statuses: [],
            volunteers: [],
            mentors: [],
            checkins: 0
        }
    }

    componentDidMount() {
        const token = cookie.load('token');

        fetch(process.env.REACT_APP_API_ENDPOINT + "/api/hackers/stats")
            .then(res => res.json())
            .then(stats => {
                this.setState({
                    applicants: stats.hackersApplied,
                    travellingFrom: stats.travellingFromCounts,
                    shirts: stats.shirts,
                    statuses: stats.statuses
                })
            })

        fetch(process.env.REACT_APP_API_ENDPOINT + "/api/mentor/all", {
            headers: {
                'authorization': 'bearer ' + token
            }
        })
            .then(res => res.json())
            .then(mentors => {
                this.setState({
                    mentors: mentors
                })
            })

        fetch(process.env.REACT_APP_API_ENDPOINT + "/api/volunteer/all", {
            headers: {
                'authorization': 'bearer ' + token
            }
        })
            .then(res => res.json())
            .then(volunteers => {
                this.setState({
                    volunteers: volunteers,
                })
            })

        fetch(process.env.REACT_APP_API_ENDPOINT + "/api/checkin/checkinStats", {
            headers: {
                'authorization': 'bearer ' + token
            }
        })
            .then(res => res.json())
            .then(res => {
                this.setState({
                    checkins: res.checkIns,
                })
            })
    }
    compareFrom(a, b) {
        if (a.count < b.count) {
            return 1;
        }
        if (a.count > b.count) {
            return -1;
        }
        return 0;
    }

    render() {
        return (
            <div className="ui grid">
                <div className="sixteen wide column">
                    <h1>Dashboard</h1>
                </div>
                <div className="sixteen wide column">
                    <h3>Checkins: {this.state.checkins}</h3>
                </div>
                <div className="eight wide column">
                    <table className="ui celled fixed table">
                        <thead>
                            <tr><th>Status</th>
                                <th>Amount</th>
                            </tr></thead>
                        <tbody>
                            {
                                this.state.statuses.map(elem => {
                                    return (
                                        <tr>
                                            <td data-label="Size">{elem._id}</td>
                                            <td data-label="Amount">{elem.count}</td>
                                        </tr>)
                                })
                            }
                        </tbody>
                    </table>
                    <table className="ui celled fixed table">
                        <thead>
                            <tr><th>University</th>
                                <th>Amount</th>
                            </tr></thead>
                        <tbody>
                            {
                                this.state.travellingFrom.sort(this.compareFrom).map(elem => {
                                    return (
                                        <tr>
                                            <td data-label="University">{elem._id}</td>
                                            <td data-label="Amount">{elem.count}</td>
                                        </tr>)
                                })
                            }
                        </tbody>
                    </table>
                    <table className="ui celled fixed table">
                        <thead>
                            <tr><th>Size</th>
                                <th>Amount</th>
                            </tr></thead>
                        <tbody>
                            {

                                this.state.shirts.map(elem => {
                                    return (
                                        <tr>
                                            <td data-label="Size">{elem._id}</td>
                                            <td data-label="Amount">{elem.count}</td>
                                        </tr>)
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="eight wide column">
                    <table className="ui celled fixed table">
                        <thead>
                            <tr><th>Type</th>
                                <th>Amount</th>
                            </tr></thead>
                        <tbody>
                            <tr>
                                <td data-label="Size">Volunteers</td>
                                <td data-label="Amount">{this.state.volunteers.length}</td>
                            </tr>
                            <tr>
                                <td data-label="Size">Mentors</td>
                                <td data-label="Amount">{this.state.mentors.length}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
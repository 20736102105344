import React from 'react'
import cookie from 'react-cookies'
import { Button, Icon } from 'semantic-ui-react';

export default class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            pageToken: '',
            prevPageToken: '',
            pageTokenQueue: [],
            loading: true
        }
    }

    deleteUser = (uid) => {
        this.setState({
            loading: true
        })
        const token = cookie.load('token');
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/users/delete/' + uid, {
            method: "POST",
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            if (res.status === 200) {
                this.fetchUsers();
            }
        }).catch(err => {
            console.log(err);
        })

    }

    componentWillMount() {
        this.fetchUsers('');
    }

    fetchUsers = (pageToken) => {
        var token = cookie.load('token');
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/users/list?page=' + pageToken, {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            res.json().then(x => {
                console.log(x);
                this.setState({
                    users: x.users,
                    loading: false,
                    pageToken: x.pageToken
                })
            })
        }).catch(err => {
            console.log(err);
        })
    }

    incPage = () => {
        var tempQueue = this.state.pageTokenQueue;
        tempQueue.push(this.state.prevPageToken);
        this.setState({
            prevPageToken: this.state.pageToken,
            pageTokenQueue: tempQueue
        })
        this.fetchUsers(this.state.pageToken);

    }

    decPage = () => {
        var tempQueue = this.state.pageTokenQueue;
        var pageToken = tempQueue.pop() || "";
        this.setState({
            pageTokenQueue: tempQueue
        })
        this.fetchUsers(pageToken);
    }

    render() {
        if (this.state.loading) {
            return (
                <table className="ui celled striped table">
                    <thead>
                        <tr>
                            <th colSpan="4">
                                Firebase Users
                            </th>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <th>Last Sign In</th>
                            <th>UID</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="center aligned">
                            <td colSpan="5">
                                <i className="huge spinner loading icon"></i>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot className="full-width">
                        <tr>
                            <th colSpan="5">
                                <div className="ui right floated small primary labeled icon button" onClick={this.incPage}>
                                    Next<i className="icon angle right"></i>
                                </div>
                                <div className="ui left floated small primary labeled icon button" onClick={this.decPage}>
                                    <i className="icon angle left"></i> Prev
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            )
        } else {
            return (
                <table className="ui celled striped table">
                    <thead>
                        <tr>
                            <th colSpan="4">
                                Users (Firebase)
                            </th>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <th>Last Sign In</th>
                            <th>UID</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.users.map(user => {
                                return (<tr key={user.uid}>
                                    <td className="collapsing">
                                        <i className="folder icon"></i>{user.email}</td>
                                    <td>{user.metadata.lastSignInTime}</td>
                                    <td className="collapsing"><a style={{color:'black'}} href={"/Admin/User/" + user.uid}>{user.uid}</a></td>
                                    <td className="right aligned">
                                        <Button onClick={this.deleteUser.bind(this, user.uid)} color="red"><Icon name="delete"></Icon></Button>
                                        <button className="ui button label icon blue"><i className="reset icon"></i>Reset Password</button>
                                    </td>
                                </tr>)
                            })
                        }
                    </tbody>
                    <tfoot className="full-width">
                        <tr>
                            <th colSpan="5">
                            {
                                this.state.pageTokenQueue.length !== 0 && 
                                <div className="ui left floated small primary labeled icon button" onClick={this.decPage}>
                                    <i className="icon angle left"></i> Prev
                                </div>
                            }
                                <div className="ui right floated small primary labeled icon button" onClick={this.incPage}>
                                    Next<i className="icon angle right"></i>
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            )
        }
    }
}
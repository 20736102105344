import React from 'react'

function convertKey(key) {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export default class UserApplication extends React.Component {
    render() {
        if (this.props.info) {
            return (
                <div>
                    <h4>Your Application:</h4>
                    <div id="ApplicationSection">
                        {
                            Object.keys(this.props.info).map(key => {
                                if (key !== "__v") {
                                    return(<p style={{ color: "black" }} key={key}>{convertKey(key)}: {this.props.info[key]}</p>);
                                }
                                return "";
                            })}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                </div>
            )
        }
    }
}
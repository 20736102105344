import React from 'react'

export default function (props) {
    var barStyle = {
        display: "flex",
        placeContent: "center",
        placeItems: "center"
    }

    var headerStyle = {
        color: "white"
    }

    if (props.enabled && !props.message) {
        return (<div></div>)
    } else {
        return (
            <div id="UpdateBar" style={barStyle}>
                <h4 style={headerStyle}>{props.message}</h4>
            </div>
        )
    }
}

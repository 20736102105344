import React from 'react'
import cookie from 'react-cookies'
import { Button, Icon } from 'semantic-ui-react';

export default class SingleUser extends React.Component {
    constructor(props) {
        super(props);
        const token = cookie.load('token');
        this.state = {
            user: {},
            uid: props.match.params.user,
            loading: true,
        }

        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/users/' + props.match.params.user, {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            if (res.status !== 200) {
                alert("User Not Found");
            } else {
                res.json().then(x => {
                    this.setState({
                        user: x || {},
                        loading: false,
                    });
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }

    resetPassword = () => {
        const token = cookie.load('token');
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/users/reset/' + this.state.user.email, {
            method: "POST",
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            if (res.status !== 200) {
                alert("Error resetting password");
            } else {
                res.text().then(url => {
                    this.setState({
                        resetPasswordUrl: url
                    })
                    
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }

    deleteUser = () => {
        const token = cookie.load('token');
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/users/delete/' + this.state.uid, {
            method: "POST",
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            if (res.status === 200) {
                this.props.history.goBack();
            }
        }).catch(err => {
            console.log(err);
        })

    }

    disableUser = () => {

    }

    render() {
        if (!this.state.loading) {
            return (
                <div>
                    <ul style={{ color: "black" }}>
                        <li>Uid: {this.state.user.uid}</li>
                        <li>Email: {this.state.user.email}</li>
                        <li>Last Signin: {this.state.user.metadata.lastSignInTime}</li>
                        <li>Created: {this.state.user.metadata.creationTime}</li>
                        <li>Disabled: {this.state.user.disabled.toString()}</li>
                        {
                            this.state.resetPasswordUrl && 
                            <li><a style={{color:"blue"}} href={this.state.resetPasswordUrl}>Reset password link.</a></li>
                        }
                    </ul>
                    <br />
                    <Button
                        onClick={this.disableUser}
                        color="orange">
                        <Icon name="stop" />
                        Disable
                    </Button>
                    <Button
                        onClick={this.resetPassword}
                        color="blue">
                        <Icon name="refresh" />
                        Reset Password
                    </Button>
                    <Button
                        onClick={this.deleteUser}
                        color="red">
                        <Icon name="delete" />
                        Delete
                    </Button>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
}
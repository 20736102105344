import React from 'react'
import { withRouter } from 'react-router-dom'

export default withRouter(class GodHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "Dashboard"
        }
    }

    navigateTo = (page) => {
        this.setState({ selected: page })
        this.props.history.push("/Admin/" + page)
    }

    render() {
        return (
            <div className="ui menu">
                <div className="header item" onClick={this.navigateTo.bind(this, 'Dashboard')}>
                    Dashboard
                </div>
                <div className={(this.state.selected === "Users") ? "item active" : "item"} onClick={this.navigateTo.bind(this, 'Users')}>
                    Users
                </div>
                <div className={(this.state.selected === "Applications") ? "item active" : "item"} onClick={this.navigateTo.bind(this, 'Applications')}>
                    Applications
                </div>
                <div className={(this.state.selected === "Volunteers") ? "item active" : "item"} onClick={this.navigateTo.bind(this, 'Volunteers')}>
                    Volunteers
                </div>
                <div className={(this.state.selected === "Mentors") ? "item active" : "item"} onClick={this.navigateTo.bind(this, 'Mentors')}>
                    Mentors
                </div>
                <div className={(this.state.selected === "Checkins") ? "item active" : "item"} onClick={this.navigateTo.bind(this, 'Checkins')}>
                    Check Ins
                </div>
                <div className="right menu">
                    <a className="item" href="/">Homepage</a>
                    <a className="item" href="/logout">Logout</a>
                </div>
            </div>
        )
    }
});
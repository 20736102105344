import React from 'react'
import cookie from 'react-cookies'

export default class Hackers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mentors: []
        }
        console.log(this.state)
    }

    componentDidMount() {
        const token = cookie.load('token');
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/mentor/list', {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            res.json().then(x => {
                console.log(x);
                this.setState({
                    mentors: x || []
                });
            })
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        return (
            <table className="ui celled striped table">
                <thead>
                    <tr>
                        <th colSpan="5">
                            Mentors : {this.state.mentors.length}
                        </th>
                    </tr>
                    <tr>
                        <th>_id</th>
                        <th>User</th>
                        <th>Name</th>
                        <th>University</th>
                        <th>Availability</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.mentors.map(mentor => {
                            return (<tr key={mentor._id}>
                                <td className="collapsing">
                                    <a style={{color:'black'}} href={"/Admin/Mentor/" + mentor._id}>{mentor._id}</a>
                                </td>
                                <td><a style={{color:'black'}} href={"/Admin/User/" + mentor._user}>{mentor._user}</a></td>
                                <td>{mentor.lastName}, {mentor.firstName}</td>
                                {!mentor.attendUottawa && <td> {mentor.schoolIfNot}</td>}
                                {mentor.attendUottawa && <td>uOttawa</td>}
                                <td>Friday:{mentor.fridayAvailability} <br/>
                                Saturday:{mentor.saturdayAvailability}<br/>
                                Sunday:{mentor.sundayAvailability}</td>
                            </tr>)
                        })
                    }
                </tbody>
                <tfoot className="full-width">
                    <tr>
                        <th colSpan="5">
                            <div className="ui right floated small primary labeled icon button" onClick={this.incPage}>
                                Next<i className="icon angle right"></i>
                            </div>
                            <div className="ui left floated small primary labeled icon button" onClick={this.decPage}>
                                <i className="icon angle left"></i> Prev
                            </div>
                        </th>
                    </tr>
                </tfoot>
            </table>
        )
    }
}
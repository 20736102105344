import React from 'react'
import cookie from 'react-cookies'
import UserApplication from '../../components/UserApplication';
import { Dropdown, Button, Form } from 'semantic-ui-react';

const STATUS_OPTIONS = ['PENDING', 'ACCEPTED', 'WAITLISTED', 'REJECTED'];

function generateOptions(list) {
    var toReturn = [];
    list.forEach(element => {
        toReturn.push({ key: element, text: element, value: element })
    });
    return toReturn;
}

export default class SingleUser extends React.Component {
    constructor(props) {
        super(props);
        const token = cookie.load('token');
        this.state = {
            user: {},
            uid: props.match.params.user,
            loading: true,
            status: ""
        }

        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/hackers/' + props.match.params.application, {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            if (res.status !== 200) {
                alert("User Not Found");
            } else {
                res.json().then(x => {
                    console.log(x);
                    this.setState({
                        application: x || {},
                        loading: false,
                        status: x.status
                    });
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }
    changeStatus =() => {
        const token = cookie.load('token');
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/hackers/updateStatus/${this.state.application._id}/${this.state.status}`, {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            if (res.status === 404) {
                alert("Method not found");
            } else if (res.status !== 200) {
                alert("User Not Found");
            } else {
                res.json().then(x => {
                    console.log(x);
                    this.setState({
                        application: x || {},
                        loading: false,
                        status: x.status
                    });
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }
    render() {
        return (
            <div>
                <UserApplication info={this.state.application} />
                <br/>
                <Form onSubmit={this.changeStatus}>
                <Form.Group>
                    <Dropdown value={this.state.status} onChange={(e) => this.setState({status: e.target.textContent})} options={generateOptions(STATUS_OPTIONS)} fluid selection></Dropdown>
                    <Button>Submit</Button>

                </Form.Group>
                </Form>
            </div>
        )
    }
}
import React from 'react';

import faq_list from '../faqs.json'

export default class FAQ extends React.Component {
    constructor(props) {
        super(props);
        faq_list.map(x => {
            x.visible = false;
            return x;
        })
        this.state = {
            faqs: faq_list,
            selected: {}
        }
    }

    setVisible = (faq) => {
        var index = this.state.faqs.indexOf(faq);
        var tempList = this.state.faqs;
        var setTo = !tempList[index].visible
        tempList.map(x => {
            x.visible = false;
            return x;
        })

        tempList[index].visible = setTo;
        this.setState({
            faqs: tempList,
            selected: faq

        })
    }

    getHeight(id) {
        const container = document.getElementById(id);
        if (container) {
            const answer = container.getElementsByTagName("p")[0];
            return answer.clientHeight;
        }
    }

    render() {
        let yourArray = this.state.faqs;
        let halfwayThrough = Math.floor(yourArray.length / 2)

        let arrayFirstHalf = yourArray.slice(0, halfwayThrough);
        let arraySecondHalf = yourArray.slice(halfwayThrough, yourArray.length);
        return (
            <div id="FAQ">
            <div className='event-title'>
                <h2 className='desk text-purple italic'>Frequently Asked Questions
                </h2>
                <h2 className='mobile text-purple italic'>FAQs</h2>
            </div>
                <div id="FAQmain">
                    <div>
                        {
                            arrayFirstHalf.map((faq, index) => {
                                return (<div key={faq.question}>
                                    <div
                                        id={`accordion__title-A${index}`}
                                        data-slidetoggle={`accordion__item-${index}`}
                                        aria-selected="false"
                                        aria-controls={`accordion__body-${index}`}
                                        className={"unselectable accordion__title " + (faq.visible ? "expanded" : "non-expanded")}
                                        role="tab"
                                        tabIndex="0"
                                        onClick={this.setVisible.bind(this, faq)}>
                                        <h3>{faq.question} </h3>
                                    </div>
                                    <div id={`accordion__item-A${index}`}
                                        className={"accordion__body " + (faq.visible ? "" : "accordion__body--hidden")}
                                        aria-hidden={faq.visible ? "false" : "true"}
                                        aria-labelledby={`accordion__title-${index}`}
                                        role="tabpanel"
                                        style={{height: faq.visible ? this.getHeight(`accordion__item-A${index}`) : "0px"}}
                                    >
                                        <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                                    </div>

                                </div>)
                            })
                        }
                    </div>
                    <div>
                        {
                            arraySecondHalf.map((faq, index) => {
                                return (<div key={faq.question}>
                                    <div
                                        id={`accordion__title-B${index}`}
                                        data-slidetoggle={`accordion__item-${index}`}
                                        aria-selected="false"
                                        aria-controls={`accordion__body-${index}`}
                                        className={"unselectable accordion__title " + (faq.visible ? "expanded" : "non-expanded")}
                                        role="tab"
                                        tabIndex="0"
                                        onClick={this.setVisible.bind(this, faq)}>
                                        <h3>{faq.question} </h3>
                                    </div>
                                    <div id={`accordion__item-B${index}`}
                                        className={"accordion__body " + (faq.visible ? "" : "accordion__body--hidden")}
                                        aria-hidden={faq.visible ? "false" : "true"}
                                        aria-labelledby={`accordion__title-${index}`}
                                        role="tabpanel"
                                        style={{height: faq.visible ? this.getHeight(`accordion__item-B${index}`) : "0px"}}
                                    >
                                        <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                                    </div>

                                </div>)
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

import React from 'react'

import ZuckBucks from '../img/facebook.svg'
import Twatter from '../img/twitter.svg'
import TheGram from '../img/insta.svg'

export default class Footer extends React.Component {
    render() {
        return (
            <div id='Footer'>
                <div className='col-lg-4 offset-lg-4'>
                    <h2>Still got questions?</h2>
                    <p>Contact <a href='mailto:hi@uottahack.ca'>hi@uottahack.ca</a> or any of our social media below</p>
                    <div className='social-links'>
                        <a href='https://www.facebook.com/uottahack/'><img src={ZuckBucks} alt='facebook' /></a>
                        <a href='https://twitter.com/uottahack/'><img src={Twatter} alt='twitter' /></a>
                        <a href='https://www.instagram.com/uottahack/'><img src={TheGram} alt='instagram' /></a>
                    </div>
                </div>
            </div>
        )
    }
}
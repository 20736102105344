import React from 'react'
import cookie from 'react-cookies'
import UserApplication from '../../components/UserApplication';

export default class SingleUser extends React.Component {
    constructor(props) {
        super(props);
        const token = cookie.load('token');
        this.state = {
            user: {},
            uid: props.match.params.user,
            loading: true,
            status: ""
        }

        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/volunteer/' + props.match.params.application, {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            if (res.status !== 200) {
                alert("User Not Found");
            } else {
                res.json().then(x => {
                    this.setState({
                        volunteer: x || {},
                        loading: false,
                        status: x.status
                    });
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }
    render() {
        return (
            <div>
                <UserApplication info={this.state.volunteer} />
                <br/>
            </div>
        )
    }
}
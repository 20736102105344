import React from 'react'
import cookie from 'react-cookies'
import { Modal, Header, Button } from 'semantic-ui-react'

export default class Hackers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkins: [],
            modalShow: false,
            latestCheckin: {}
        }
    }

    componentDidMount() {
        const token = cookie.load('token');
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/checkin/list', {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            res.json().then(x => {
                if (x.status === "success") {
                    this.setState({
                        checkins: x.checkins || []
                    });
                }
            })
        }).catch(err => {
            console.log(err);
        });
    }

    checkIn = (e) => {
        const token = cookie.load('token');
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/link/${this.state.email}/${Math.random().toString().replace("0.", "")}`, {
            method: "POST",
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            res.json().then(x => {
                if (x.status === "success") {
                    this.setState({
                        modalShow: true,
                        latestCheckin: x.application
                    })
                    this.componentDidMount();
                } else {
                    if (x.innerError.errmsg) {
                        alert(x.innerError.errmsg);
                    } else {
                        alert(x.innerError);
                    }
                }
            })
        }).catch(err => {
            console.log(err);
        });
    }

    changeEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    close = () => {
        this.setState({
            modalShow: false
        })
    }

    unLink = (email) => {
        const token = cookie.load('token');
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/unlink/email/${email}`, {
            method: "POST",
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            res.json().then(x => {
                if (x.status === "success") {
                    alert("Checkin Removed.")
                    this.componentDidMount();
                } else {
                    if (x.innerError.errmsg) {
                        alert(x.innerError.errmsg);
                    } else {
                        alert(x.innerError);
                    }
                }
            })
        }).catch(err => {
            console.log(err);
        });

    }

    render() {
        return (
            <div>
                <form onSubmit={this.checkIn}>
                    <div className="ui action input">
                        <input type="text" name="email" placeholder="Check In" value={this.email} onChange={this.changeEmail} />
                        <button className="ui button">Submit</button>
                    </div>
                </form>
                <Modal
                    open={this.state.modalShow}
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    onClose={this.close}>
                    <Modal.Header>Checkin Successful</Modal.Header>
                    <Modal.Content image>
                        <Modal.Description>
                            <Header>Shirt Size: {this.state.latestCheckin.shirtSize}</Header>
                            <Header>Status: {this.state.latestCheckin.status}</Header>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                <table className="ui celled striped table">
                    <thead>
                        <tr>
                            <th colSpan="5">
                                Checkins : {this.state.checkins.length}
                            </th>
                        </tr>
                        <tr>
                            <th>_id</th>
                            <th>User</th>
                            <th>Email</th>
                            <th>Application</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.checkins.map(checkin => {
                                return (<tr key={checkin._id}>
                                    <td className="collapsing"><a style={{ color: 'black' }} href={"/Admin/Checkin/" + checkin._id}>{checkin._id}</a></td>
                                    <td className="collapsing"><a style={{ color: 'black' }} href={"/Admin/User/" + checkin.userUID}>{checkin.userUID}</a></td>
                                    <td className="collapsing">{checkin.email}</td>
                                    <td className="collapsing"><a style={{ color: 'black' }} href={"/Admin/Application/" + checkin.applicationId}>{checkin.applicationId}</a></td>
                                    <td className="collapsing"><Button onClick={this.unLink.bind(this,checkin.email)}>UnLink</Button></td>
                                </tr>)
                            })
                        }
                    </tbody>
                    <tfoot className="full-width">
                        <tr>
                            <th colSpan="5">
                                <div className="ui right floated small primary labeled icon button" onClick={this.incPage}>
                                    Next<i className="icon angle right"></i>
                                </div>
                                <div className="ui left floated small primary labeled icon button" onClick={this.decPage}>
                                    <i className="icon angle left"></i> Prev
                            </div>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }
}
import React from 'react';

import Accent from '../img/sponsor_accent.svg'

import Solace from '../img/sponsors/Solace.png'
import Innovapost from '../img/sponsors/Innovapost.png'
import Wolfram from '../img/sponsors/wolfram.png'
import Balsamiq from '../img/sponsors/balsamiq.png'
import OnePassword from '../img/sponsors/onepassword.png'
import lSpark from '../img/sponsors/lspark.png'
import DigitalOcean from '../img/sponsors/DigitalOcean.svg'
import RBR from '../img/sponsors/RBR.png'
import KanataNorth from '../img/sponsors/kanatanorthbia.png'
import Deloitte from '../img/sponsors/deloitte.png'
import Voiceflow from '../img/sponsors/voiceflow.png'
import StickerMule from '../img/sponsors/stickermule.svg'
import MNP from '../img/sponsors/MNP.png'
import ADGA from '../img/sponsors/ADGA.png'
import DRDC from '../img/sponsors/DRDC-RDDC.png'
import EWA from '../img/sponsors/ewa_logo.png'
import CDW from '../img/sponsors/cdw.svg'
import Phreesia from '../img/sponsors/phreesia.png'
import Splunk from '../img/sponsors/splunk.png'
import boc from '../img/sponsors/boc.png'
import surveymonkey from '../img/sponsors/Horizontal_Sabaeus_RGB.svg'
import cengn from '../img/sponsors/cengen.png'
import IPSG from '../img/sponsors/IPSG.png'
import raon from '../img/sponsors/raon.png'
import sparklingice from '../img/sponsors/sparklingice.png'
import bridgehead from '../img/sponsors/bridgehead.png'
import bus from '../img/sponsors/bus.svg'
import signal from '../img/sponsors/33-signal.jpg'

export default function () {
    return (
        <div>
            <div id='Sponsors'>
                <div className='container'>
                    <div className='row clearfix'>
                        <div className='col-lg-4 offset-lg-6 col-xs-12 text-white'>
                            <h2>Interested in sponsoring for uOttaHack 2021?</h2>
                            <div className='btn-sponsor btn-rounded btn-orange purple unselectable cursorpointer' id='ComingSoon'>
                                <a href='mailto:sponsorship@uottahack.ca' className='no-under'>
                                    <div className='text-white'>
                                        Click here to get in touch!
                                </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-1 desk'>
                            <img src={Accent} alt='' />
                        </div>
                    </div>
                </div>
            </div>
            <div id="Sponsor-logos">
                <h2 className="text-purple italic">Sponsors</h2>
                <div className="active-sponsors">
                    <div className="sponsor innovapost track">
                        <a href="https://innovapost.com/">
                            <img src={Innovapost} alt="Innovapost logo (track partner)" />
                        </a>
                    </div>
                    <div className="sponsor solace gold">
                        <a href="https://solace.com/">
                            <img src={Solace} alt="Solace logo (gold tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor MNP silver">
                        <a href="https://www.mnp.ca/en">
                            <img src={MNP} alt="MNP logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor deloitte silver">
                        <a href="http://www2.deloitte.com/global/">
                            <img src={Deloitte} alt="Deloitte logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor RBR silver">
                        <a href="https://rbr-global.com/">
                            <img src={RBR} alt="RBR logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor ADGA silver">
                        <a href="https://www.adga.ca/">
                            <img src={ADGA} alt="ADGA logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor DRDC silver">
                        <a href="http://www.drdc-rddc.gc.ca/">
                            <img src={DRDC} alt="DRDC | RDDC logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor EWA silver">
                        <a href="https://www.intertek.com/cybersecurity/ewa-canada/">
                            <img src={EWA} alt="EWA-Canada logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor CDW silver">
                        <a href="https://www.cdw.ca/">
                            <img src={CDW} alt="CDW logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor Phreesia silver">
                        <a href="https://www.phreesia.com/">
                            <img src={Phreesia} alt="Phreesia logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor splunk silver">
                        <a href="https://www.splunk.com/">
                            <img src={Splunk} alt="Splunk logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor boc silver">
                        <a href="https://www.bankofcanada.ca/">
                            <img src={boc} alt="Bank of Canada logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor surveymonkey silver">
                        <a href="https://www.surveymonkey.com/">
                            <img src={surveymonkey} alt="SurveyMonkey logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor signal silver">
                        <a href="https://forces.ca/en/units/99/">
                            <img src={signal} alt="33 Signal Regiment logo (silver tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor kanatanorthbia bronze">
                        <a href="https://www.kanatanorthba.com/">
                            <img src={KanataNorth} alt="Kanata North BIA logo (bronze tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor ipsg bronze">
                        <a href="https://ipsgtechnology.com/">
                            <img src={IPSG} alt="IPSG logo (bronze tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor cengen bronze">
                        <a href="https://www.cengn.ca/">
                            <img src={cengn} alt="CENGN logo (bronze tier sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor wolfram in-kind">
                        <a href="https://www.wolfram.com/">
                            <img src={Wolfram} alt="Wolfram logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor balsamiq in-kind">
                        <a href="https://balsamiq.com/">
                            <img src={Balsamiq} alt="Balsamiq logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor onepassword in-kind">
                        <a href="https://1password.com/">
                            <img src={OnePassword} alt="1Password logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor digitalocean in-kind">
                        <a href="https://www.digitalocean.com/">
                            <img src={DigitalOcean} alt="Digital Ocean logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor lspark in-kind">
                        <a href="https://www.l-spark.com/">
                            <img src={lSpark} alt="L-SPARK logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor bus in-kind">
                        <a href="https://www.bus.com/">
                            <img src={bus} alt="bus.com logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor voiceflow in-kind">
                        <a href="https://www.voiceflow.com/">
                            <img src={Voiceflow} alt="Voiceflow logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor stickermule in-kind">
                        <a href="http://hackp.ac/mlh-stickermule-hackathons">
                            <img src={StickerMule} alt="StickerMule logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor raon in-kind">
                        <a href="https://www.raonkitchen.com/">
                            <img src={raon} alt="Raon logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor sparklingice in-kind">
                        <a href="https://www.sparklingice.com/">
                            <img src={sparklingice} alt="Sparkling Ice logo (in-kind sponsor)" />
                        </a>
                    </div>
                    <div className="sponsor bridgehead in-kind">
                        <a href="https://www.bridgehead.ca/">
                            <img src={bridgehead} alt="Bridgehead logo (in-kind sponsor)" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

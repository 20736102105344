import React from "react";

import MLH from "../img/partners/mlh-logo-color.png";
import GitHub from "../img/partners/GitHub_Logo.png";
import uOttawa from "../img/partners/uottawa_hor_black.png";

export default function() {
    return (
        <div id="partners">
            <h2 className="text-purple italic">Community Partners</h2>
            <div className="active-partners">
                <div className="partner mlh">
                    <a href="https://mlh.io/">
                        <img src={MLH} alt="MLH logo" />
                    </a>
                </div>
                <div className="partner github">
                    <a href="https://github.com/">
                        <img src={GitHub} alt="GitHub logo" />
                    </a>
                </div>
                <div className="partner uottawa">
                    <a href="https://www.uottawa.ca/">
                        <img src={uOttawa} alt="uOttawa logo" />
                    </a>
                </div>
            </div>
        </div>
    );
}

import React from 'react'

function convertKey(key) {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export default class Meals extends React.Component {

    render() {
        if (this.props.meals) {
            return (
                <div>
                    <h4>Da Meals:</h4>
                    <div id="ApplicationSection">
                        {
                            Object.keys(this.props.meals).map(key => {
                                if (key !== "__v" && (key.includes("1") || key.includes("2"))) {
                                    return (
                                        <div key={key}>
                                            <div className="ui slider checkbox">
                                                <input type="checkbox" name="newsletter" checked={this.props.meals[key]} onChange={this.props.changeMealCheck} data-key={key} />
                                                <label>{convertKey(key)}</label>
                                            </div>
                                            <br />
                                        </div>
                                    )
                                }
                                return "";
                            })}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                </div>
            )
        }
    }
}
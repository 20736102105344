import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import cookie from 'react-cookies'

export default function God(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false
      };
    }

    componentDidMount() {
      const token = cookie.load('token');
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/users/admin", {
        headers: {
          'authorization': 'bearer ' + token
        }
      }).then(x => {
        console.log(x.status)
        if (x.status !== 200) {
          this.setState({ loading: false, redirect: true })
        } else {
          this.setState({ loading: false, redirect: false })
        }
      }).catch(err => {
        this.setState({ loading: false, redirect: true })

      })
    }

    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to={"/Login?redirect=" + this.props.location.pathname}  />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment >
      );
    }
  }
}
import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../img/logo.png'

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
        }
    }
    
    hamburger = () => {
        if (this.state.toggle) {
            document.getElementById("Nav").classList.remove("toggle");
        } else {
            document.getElementById("Nav").classList.add("toggle");
        }
        this.setState({
            toggle:!this.state.toggle
        })
    }

    exit = () => {
        if (this.state.toggle) {
            document.getElementById("Nav").classList.remove("toggle");
        } else {
            document.getElementById("Nav").classList.add("toggle");
        }
        this.setState({
            toggle:!this.state.toggle
        })
    }
    
    componentDidMount() {
        var links = document.getElementsByClassName("nav-link");

        var itemOnClick = () => {
            if (this.state.toggle) {
                document.getElementById("Nav").classList.remove("toggle");
            } else {
                document.getElementById("Nav").classList.add("toggle");
            }
            this.setState({
                toggle:!this.state.toggle
            })
        }

        for (let item of links) {
            item.onclick = itemOnClick;
        }
    }

    render() {
        return (
            <div id="Header">
                <div id="HeaderInner">
                    <div id="Logo">
                        <Link to="/">
                            <img src={Logo} alt="" />
                        </Link>
                    </div>
                    <div id="Nav">
                        <ul className="nav-list">
                            <li>
                                <a className="nav-link" href="#About">About Us</a>
                            </li>
                            <li>
                                <a className="nav-link" href="#Location">Location</a>
                            </li>
                            <li>
                                <a className="nav-link" href="#FAQ">FAQ</a>
                            </li>
                            <span id="Exit" onClick={this.exit}>X</span>
                        </ul>
                        <ul className="nav-list-collapse">
                            <li id="Hamburger" onClick={this.hamburger}>
                                ☰
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
import React from 'react'

import MapAccentLeft from '../img/map_accent_left.svg'
import MapAccentRight from '../img/map_accent_right.svg'

export default class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            center: { lat: 45.4220039, lng: -75.6817997 },
            zoom: 15
        }
    }

    render() {
        return (
            <div id='Location'>
                <div className='row'>
                    <div className='col-sm-6 offset-sm-3'>
                        <h2 className='text-purple italic'>Where we hack</h2>
                        <img src={MapAccentLeft} alt='' className='left-accent desk' />
                        <img src={MapAccentRight} alt='' className='right-accent desk' />
                        <iframe title="map" id='map' src='https://www.google.com/maps/embed/v1/place?key=AIzaSyDNSnR_xrWL94c_mXWdAKwr4k4KWkhoEE4&q=uOttawa+CRX' allowFullScreen>
                        </iframe>
                    </div>
                </div>
            </div>
            );
        }
}
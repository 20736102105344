import React from 'react';

export default function() {
    return (

        <div id='LastYear'>
            <div className='first'>
                <div className='ly-title'>
                    <h2 className='text-white italic'>uOttaHack 2019 had...</h2>
                </div>
                <div className='row'>
                    <div className='ly-grid col-md-6 offset-md-3'>
                        <div className='ly-segment'>
                            <div className='text-white'>
                                1300+
                                <span>Applications</span>
                            </div>
                        </div>
                        <div className='ly-segment'>
                            <div className='text-white'>
                                400+
                                <span>Hackers</span>
                            </div>
                        </div>
                        <div className='ly-segment'>
                            <div className='text-white'>
                                30+
                                <span>Schools</span>
                            </div>
                        </div>
                        <div className='ly-segment'>
                            <div className='text-white'>
                                10
                                <span>Puppers</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import cookie from 'react-cookies'
import Meals from '../../components/Meals';
import UserApplication from '../../components/UserApplication';

export default class SingleUser extends React.Component {
    constructor(props) {
        super(props);
        const token = cookie.load('token');
        this.state = {
            meals: {},
            nfc: {},
            loading: true,
        }

        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/checkin/' + props.match.params.id, {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => res.json()).then(res => {
            if (res.status === "success"){
                this.setState({
                    meals: res.userMeal || {},
                    nfc: res.userNFC || {},
                    loading: false,
                });
            }
        }).catch(err => {
            console.log(err);
        })
    }

    changeStatus =() => {
        const token = cookie.load('token');
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/updateStatus/${this.state.application._id}/${this.state.status}`, {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            if (res.status === 404) {
                alert("Method not found");
            } else if (res.status !== 200) {
                alert("User Not Found");
            } else {
                res.json().then(x => {
                    this.setState({
                        application: x || {},
                        loading: false,
                        status: x.status
                    });
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }

    changeMealCheck = (e) => {
        const token = cookie.load('token');
        var meals = this.state.meals;
        var value = !meals[e.target.dataset["key"]];
        var key = e.target.dataset["key"]
        meals[key] = value;
        this.setState({
            meals: meals
        })

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/checkin/setMeal/${this.props.match.params.id}/${e.target.dataset["key"]}/${value}`, {
            method: "POST",
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => res.json()).then(res => {
            if (res.status !== "success"){
                meals[key] = !value;
                this.setState({
                    meals: meals
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        return (
            <div>
                <Meals meals={this.state.meals} changeMealCheck={this.changeMealCheck} />
                <br/>
                <UserApplication info={this.state.nfc} />
            </div>
        )
    }
}
import React from 'react'
import cookie from 'react-cookies'
import { Link } from 'react-router-dom'

export default class Hackers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hackers: []
        }
        console.log(this.state)
    }

    componentDidMount() {
        const token = cookie.load('token');
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/hackers/list', {
            headers: {
                'authorization': 'bearer ' + token
            }
        }).then(res => {
            res.json().then(x => {
                console.log(x);
                this.setState({
                    hackers: x || []
                });
            })
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        return (
            <table className="ui celled striped table">
                <thead>
                    <tr>
                        <th colSpan="5">
                            Applications : {this.state.hackers.length}
                        </th>
                    </tr>
                    <tr>
                        <th>_id</th>
                        <th>User</th>
                        <th>University</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.hackers.map(hacker => {
                            return (<tr key={hacker._id}>
                                <td className="collapsing">
                                    {hacker._id}
                                </td>
                                <td>{hacker.lastName}, {hacker.firstName}</td>
                                <td>{hacker.university}</td>
                                <td className="collapsing">{hacker.status}</td>
                                <td className="right aligned">
                                    <button className="ui icon button red"><i className="delete icon"></i></button>
                                    <Link style={{ color: "black" }} to={"/Admin/User/" + hacker._user}>
                                        <button className="ui icon button green"><i className="user icon"></i></button>
                                    </Link>
                                    <Link style={{ color: "black" }} to={"/Admin/Application/" + hacker._id}>
                                        <button className="ui icon button green"><i className="eye icon"></i></button>
                                    </Link>
                                </td>
                            </tr>)
                        })
                    }
                </tbody>
                <tfoot className="full-width">
                    <tr>
                        <th colSpan="5">
                            <div className="ui right floated small primary labeled icon button" onClick={this.incPage}>
                                Next<i className="icon angle right"></i>
                            </div>
                            <div className="ui left floated small primary labeled icon button" onClick={this.decPage}>
                                <i className="icon angle left"></i> Prev
                            </div>
                        </th>
                    </tr>
                </tfoot>
            </table>
        )
    }
}
import React, { Component } from 'react';

import Footer from "../components/Footer.jsx"
import Header from "../components/Header.jsx"
import Location from '../components/Location.jsx'
import UpdateBar from '../components/UpdateBar.jsx'

import Sponsors from '../components/Sponsors.jsx';
import Partners from '../components/Partners.jsx';
import LastYear from '../components/LastYear.jsx';
import About from '../components/About.jsx';
import FAQ from '../components/FAQ'

import firebase from 'firebase/app';
import 'firebase/auth';

export default class Home extends Component {
    constructor() {
        super();
        this.state = {
            UpdateBar: false,
            UpdateBarMessage: "",
            message: 'Loading...',
            events: [
                { link: "https://www.facebook.com/events/382881409280558/", img: "/img/events/ip__tech_intern_panel_.png" },
                { link: "https://www.facebook.com/events/1240131799524584/", img: "/img/events/ip__building_web_apps_.png" },
                { link: "https://www.facebook.com/events/890901121281642/", img: "/img/events/ip__techincal_interview_workshop_.png" },
            ],
            released: true,
            windowHeight: window.outerHeight,
            loggedIn: false,
            loading: true
        }
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.auth().currentUser.getIdToken(false).then(token => {
                    this.setState({ loggedIn: true, loading: false });
                })
            } else {
                this.setState({ loggedIn: false, loading: false });
            }
        })
    }

    setReleased() {
        this.setState({ released: true }).bind(this);
    }

    applyNow() {
        if (this.state.released) {
            this.props.history.push("/Hacker/Dashboard")
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.UpdateBar &&
                    <UpdateBar message={this.state.UpdateBarMessage} />

                }
                <Header />
                <div id="Body">
                    <div id="Jumbo"
                        style={{ height: (150 + this.state.windowHeight) + "px" }}
                        className="full-height" >
                        <h2 id="ThankYou" className="white-font">Thank you for attending</h2>
                        <div id="Logo"
                            className="grid-item" >
                            <h1 className="main-font white-font">uOttaHack
                                <span className="secondary-font white-font">3</span>
                            </h1>
                        </div>
                        <a
                            id="mlh-trust-badge"
                            href="https://mlh.io/seasons/na-2020/events?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2020-season&utm_content=black"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="https://s3.amazonaws.com/logged-assets/trust-badge/2020/mlh-trust-badge-2020-black.svg"
                                alt="Major League Hacking 2020 Hackathon Season"
                            />
                        </a>
                        <div id="CallToAction" className="white-font">
                            <h2>Canada's Capital's Largest Hackathon</h2>
                            <h3>February 7 - 9, 2020</h3>
                        </div>
                    </div>
                    <About />
                    <LastYear />
                    <Sponsors />
                    <Partners />
                    <Location />
                    <FAQ />
                    <Footer />
                </div>
            </div>
        );
    }
}
